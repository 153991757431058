/* custom.css */

.react-confirm-alert-overlay {
    display: flex;
    justify-content: center;
    align-items: center;
}
.left-align-label {
    text-align: center;
  }

.react-confirm-alert-body {
    width: 85%;  /* Use a percentage to ensure it scales with the screen size */
    max-width: 250px;  /* Optional: limit the maximum width on large screens */
    overflow: auto;  /* Enable scrolling if the content overflows the dialog */
}
.backgroundImage {
    /* Adjust the path below to point to your image */
  background-image: url('Ducky3.jpg'); 
    width: 100%;    
    
    background: #68c7e4;
    background-size: cover;   
    background-position: center; 
    position: relative; 
    
  }
