.duck-card-container { margin-top: 10px; }
.duck-card-image-container { display: flex; justify-content: center; }
.duck-card-image-container > div:first-of-type {
    padding: 10px; 
    width: 110px; 
    border: 1px solid gainsboro; 
    border-radius: 50%; 
    position: absolute; 
    top: -50px;
    background-color: white;
    filter: drop-shadow(0 5px 5px rgba(0, 0, 0, 0.433));
}
.duck-card-container .card-body {
    margin-top: 65px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.duck-card-container .card-subtitle { color: #999; }
.duck-card-container .card-text { margin-top: 1px; max-width: 600px; }
.duck-stats {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    gap: 20px;
}
.duck-stats > div {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.duck-stats > div > div:first-of-type {
    color: #4267B2;
    font-weight: 600;
    font-size: small;
}
.duck-stats > div > div:last-of-type {
    color: rgb(188, 188, 188);
    font-size: small;
}