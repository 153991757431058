.finder-card-group {
    margin: 20px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
}
.finder-card-group .card {
    width: 400px;
    align-self: stretch;

}
.finder-card-group img {
    margin: auto;
}
.finder-card-group .card-body .card-text {
    text-align: left;
}
.ac-container {
    position: relative;
    display: flex;
}
.ac-container img {
    z-index: 1;
}
.ac-card-background {
    background: no-repeat center center;
    background-size: cover;
    overflow: hidden;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
    border: none;
    opacity: 0.25;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}
.about-footer {
    position: relative;
    background: none;
}
.about-footer h5 {
    position: absolute;
    font-size: 9pt;
    top: -7px;
    left: var(--bs-card-cap-padding-y);
    background-color: white;
    padding: 0 6px;
}
.about-footer .card-text {
    text-align: left;
}
.finder-card .card-body {
    text-align: center;
}