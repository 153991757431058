.finder-card-group {
    margin: 20px;
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    align-items: flex-start;
}
.finder-card-group .card {
    width: 400px;
    align-self: stretch;

}
.finder-card-group img {
    margin: auto;
}
.finder-card-group .card-body .card-text {
    text-align: left;
}
.approval-buttons > button:first-of-type {
    color: #dc3545 !important;
}
.approval-buttons {
    width: 100%;
}
.approval-buttons > button:last-of-type {
    color: limegreen !important;
    border-left: var(--bs-card-border-width) solid var(--bs-card-border-color);
}
.ac-container {
    position: relative;
    display: flex;
}
.ac-container img {
    z-index: 1;
}
.ac-card-background {
    background: no-repeat center center;
    background-size: cover;
    overflow: hidden;
    -webkit-border-radius: 6px 6px 0 0;
    -moz-border-radius: 6px 6px 0 0;
    border-radius: 6px 6px 0 0;
    border: none;
    opacity: 0.25;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
}