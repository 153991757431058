.hiw-container {
    width: 100%;
    margin: 0;
    padding: 60px 0;
    font-family: Poppins;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.hiw-container h2 {
    text-align: center;
    font-weight: bold;
    padding-bottom: 20px;
}
.hiw-container p {
    line-height: 28px;
    padding: 0 20px;
}
.hiw-cards .card {
    padding: 20px;
}
.hiw-cards .card-body {
    padding: 0;
}

.hiw-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    justify-content: center;
}
.hiw-cards .card {
    color: white;
    background-color: #6cd4dd;
    font-weight: 500;
    max-width: 350px;
    border: 1px solid #408990bd;
    box-shadow: 3px 3px 6px 0 #093d423d;
    position: relative;
}
.hiw-cards .card-body svg {
    position: absolute;
    font-size: 80px;
    right: 16px;
    top: 16px;
}

.hiw-cards .card-title {
    font-size: 40px;
    font-weight: bolder;
    color: #24696f;
}

.hiw-cards .card-text {
    font-size: 24px;
    text-align: left;
    padding-bottom: 14px;
}

.hiw-container > p {
    margin-top: 26px;
    line-height: 28px;
    max-width: 800px;
}

.etsy-link {
    width: 100%;
    display: flex;
    justify-content: center;
}