.welcome-container {
    width: 100%;
    margin: 0;
    padding: 50px 20px;
    background: url('../../welcome_bg.png');
    font-family: Poppins;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 340px;
}
.welcome-container h1 {
    font-weight: bold;
    padding-bottom: 20px;
}
.welcome-container p {
    line-height: 28px;
    max-width: 800px;
}
.welcome-container a {
    text-decoration: none;
    font-size: 24px;
    background-color: #37858c;
    font-weight: 700;
    border-radius: 8px;
    color: white;
}

.welcome-container a:hover {
    color: white;
    background-color: #429ea7;
}