

.app-header {
    display: flex;
    justify-content: center;
    background: url('../../header_bg.png');
    background-size: cover;
    align-items: center;
    height: 150px;
}

.app-header img {
    z-index: 2;
    margin: 0 auto;
    max-height: 75%;
}

.app-header div {
    background-color: rgba(255, 255, 255, 0.25);
    margin: 0;
    height: 150px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}